import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Kolleg*innen Zugriff auf eine bestimmte Stelle gewähren",
  "description": "Wie gewähre ich Teammitgliedern Zugriff auf eine bestimmte Stelle inklusive eingegangener Bewerbungen",
  "author": "Anna-Mai Petersen",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Zu finden unter: Webadresse Ihres Karriereportals/backend/jobs:`}</p>
    <img src="/images/gettingstarted/zugriff01.png" alt="Wie gewähre ich Teammitgliedern Zugriff" style={{
      "width": "100%"
    }} />
    <p>{`Gehen Sie zu der ausgewählten Stelle und klicken Sie auf "Anzeigen" (rot umkreist).
Dies führt zu folgender Ansicht:`}</p>
    <img src="/images/gettingstarted/zugriff02.jpg" alt="Wie gewähre ich Teammitgliedern Zugriff" style={{
      "width": "100%"
    }} />
    <p>{`Klicken Sie auf "Kollegen einladen" (unter Rubrik Team).
Es öffnet sich nun folgendes Pop-Up-Fenster:`}</p>
    <img src="/images/gettingstarted/zugriff03.jpg" alt="Wie gewähre ich Teammitgliedern Zugriff" style={{
      "width": "100%"
    }} />
    <p>{`Hier bestehen nun folgende drei Möglichkeiten:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Kollege/in wird in der Liste vorgeschlagen:`}<br parentName="p"></br>{`
`}{`An der Box vorne Zugriff aktivieren oder deaktivieren und auf Button     "Speichern" klicken. Häkchen bedeutet Kollege/in hat Zugriff, leere Box bedeutet kein Zugriff.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Kollegen/in manuell einladen:`}<br parentName="p"></br>{`
`}{`Im Feld "E-Mail" Mailadresse des Kollegen/in eingeben und auf Button "Einladen" klicken.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Zugriff nicht ändern:`}<br parentName="p"></br>{`
`}{`Sollten bereits alle Teammember Zugriff auf diese Stelle haben, können Sie durch klicken auf "Fenster schließen" das Fenster schließen.
Es werden keine Änderungen vorgenommen.`}</p>
      </li>
    </ul>
    <p>{`Das Pop-Up-Fenster schließt sich automatisch. Man befindet sich wieder auf der Stellenübersicht, eventuelle Änderungen sind gespeichert.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      